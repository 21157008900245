import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

import { ReactComponent as UpArrow } from "../../assets/icons/up_arrow.svg";
import { IGcsPlan } from "../../store/types";
import { getAverageApr } from "../../helpers/methods";
import { useWeb3React } from "@web3-react/core";

interface IFarmStats {
  gcsPlans: IGcsPlan[] | null;
}

const FarmStats: React.FC<IFarmStats> = ({ gcsPlans }) => {
  const { account } = useWeb3React();
  const [dropdown, setDropdown] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<IGcsPlan>();

  useEffect(() => {
    if (gcsPlans?.length) {
      setSelectedPlan(gcsPlans[0]);
    }
  }, [gcsPlans]);

  return (
    <>
      {account && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "2rem",
          }}
        >
          <div
            className={dropdown ? "farm_stats-dropdown active" : "farm_stats-dropdown"}
            onClick={() => setDropdown((d) => !d)}
          >
            <p className={dropdown ? "dropdown-header active" : "dropdown-header"}>
              <span>{selectedPlan?.name}</span>
              <UpArrow />
            </p>
            <AnimatePresence>
              {dropdown && (
                <motion.div
                  className="dropdown-list"
                  animate={{ opacity: 1 }}
                  initial={{ opacity: 0 }}
                  exit={{ opacity: 0 }}
                >
                  {gcsPlans?.map((plan, index) => (
                    <p key={index.toString()} onClick={() => setSelectedPlan(plan)}>
                      {plan.name}
                    </p>
                  ))}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      )}
      <div className="flex" data-position="flex-between">
        <p>APR</p>
        <b>{selectedPlan ? getAverageApr(selectedPlan.apr) : "0%"}</b>
      </div>
      <div className="flex" data-position="flex-between">
        <p>Total Staked</p>
        <b>
          {selectedPlan
            ? new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
              }).format(selectedPlan.total_staked)
            : 0}{" "}
          USDT
        </b>
      </div>
    </>
  );
};

export default FarmStats;
