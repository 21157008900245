import React from 'react';
import { useSearchParams } from 'react-router-dom';

import './Home.scss';
import Farm from './Farm';
import ReferralPage from './ReferralPage';

const Home: React.FC = () => {
  const [searchParams] = useSearchParams();
  const referral_id = searchParams.get('referral_id');

  if (referral_id) {
    return (
      <div className="pad">
        <div className="home">
          <ReferralPage referral_id={referral_id} />
        </div>
      </div>
    );
  }

  return (
    <div className="pad">
      <div className="home">
        <Farm />
      </div>
    </div>
  );
};

export default Home;
