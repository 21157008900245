import React, { useCallback, useContext, useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { AnimatePresence } from "framer-motion";

import { Button, UnlockWallet } from "../../components";
import FarmHeader from "./FarmHeader";

import { ReactComponent as ExternalLink } from "../../assets/icons/external_link.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/alert_circle.svg";
import { ReactComponent as Loader } from "../../assets/icons/transaction_processing.svg";

import { TransactionContext } from "../../store/context/TransactionContext";

import { IDepositData, IGcsPlan, IUserStakedDetails } from "../../store/types";
import Deposit from "./Deposit";
import Withdraw from "./Withdraw";
import ReferralsModal from "../../components/Modals/ReferralModal";
import { STAKING_ADDRESS } from "../../utils/address";
import WithdrawModal from "../../components/Modals/WithdrawModal";
import DepositModal from "../../components/Modals/DepositModal";
import { useUserStakingData } from "../../hooks";
import FarmStats from "./FarmStats";
import Rewards from "./Rewards";

const Farm: React.FC = () => {
  const { account, library, chainId } = useWeb3React();
  const [gcsPlans, setGcsPlans] = useState<IGcsPlan[] | null>(null);
  const { userData, isLoading, refetch } = useUserStakingData();
  const { setTransaction, loading } = useContext(TransactionContext);
  const [referralModal, setReferralModal] = useState(false);
  const [isLive, setIsLive] = useState(true);
  const [withdrawModal, setWithdrawModal] = useState<IUserStakedDetails | null>(null);
  const [depositModal, setDepositModal] = useState<IDepositData | null>(null);

  const handleGetContractDetails = useCallback(async () => {
    if (account && chainId) {
      try {
        const { getGcsPlanData } = await import("../../utils/contractMethods");
        setGcsPlans(await getGcsPlanData(account, library?.provider, chainId));
      } catch (error: any) {
        console.log(error?.message);
      }
    }
  }, [account, chainId, library]);

  useEffect(() => {
    handleGetContractDetails();
  }, [handleGetContractDetails]);

  const handleApprove = async () => {
    if (!account || !chainId) return;
    try {
      setTransaction({ loading: true, status: "pending" });
      const { setApprove } = await import("../../utils/userMethods");
      await setApprove(account, library?.provider, chainId);
      await refetch();
      setTransaction({ loading: true, status: "success" });
    } catch (error: any) {
      if (error?.code === 4001) {
        return setTransaction({
          loading: true,
          status: "error",
          message: error.message,
        });
      }
      setTransaction({ loading: true, status: "error" });
    }
  };

  const renderLiveStakingDetails = (
    <div className="staking_wrapper-card">
      {!userData.ongoingStakingDetails.length ? (
        <div>
          <p style={{ textAlign: "center" }}>No Histories Found</p>
        </div>
      ) : (
        userData.ongoingStakingDetails.map((details, index) => (
          <div className="staking_wrapper-card_content" key={index.toString()}>
            <div>
              <p>Your stake</p>
              <b>{details.staked_amount} USDT</b>
            </div>
            <div>
              <p>plan</p>
              <b>{details.days} days</b>
            </div>
            <Withdraw setWithdrawModal={setWithdrawModal} stakedDetails={details} />
          </div>
        ))
      )}
    </div>
  );

  const renderFinishedStakingDetails = (
    <div className="staking_wrapper-card">
      {!userData.finishedStakingDetails.length ? (
        <div>
          <p style={{ textAlign: "center" }}>No Histories Found</p>
        </div>
      ) : (
        userData.finishedStakingDetails.map((details, index) => (
          <div className="staking_wrapper-card_content" key={index.toString()}>
            <div>
              <p>Your stake</p>
              <b>{details.staked_amount} USDT</b>
            </div>
            <div>
              <p>plan</p>
              <b>{details.days} days</b>
            </div>
            <Rewards id={details.index} />
          </div>
        ))
      )}
    </div>
  );

  const renderMethods = (
    <div className="form_container">
      {gcsPlans && <Deposit setDepositModal={setDepositModal} gcsPlans={gcsPlans} />}
      <hr />
      <div className="staking_wrapper">
        <div className="staking_wrapper-header">
          <h3>Staked</h3>
          <div>
            <button onClick={() => setIsLive(true)} className={isLive ? "active" : undefined}>
              Live
            </button>
            <button onClick={() => setIsLive(false)} className={!isLive ? "active" : undefined}>
              Finished
            </button>
          </div>
        </div>
        {isLive ? renderLiveStakingDetails : renderFinishedStakingDetails}
      </div>
      <hr className="mb-32" />
      <div data-position="flex-between">
        {userData.referral_id !== 0 ? (
          <div data-position="flex" className="pointer" onClick={() => setReferralModal(true)}>
            <p className="text-primary">My Referrals</p>
            <InfoIcon />
          </div>
        ) : (
          <div data-position="flex" className="pointer" onClick={() => setReferralModal(true)}>
            <p className="text-primary">My Referrals</p>
            <InfoIcon />
          </div>
        )}
        <a
          href={`https://testnet.bscscan.com/address/${STAKING_ADDRESS[97]}`}
          target="_blank"
          rel="noopener noreferrer"
          data-position="flex"
        >
          <p style={{ color: "#0F52FF" }}>View Contract</p>
          <ExternalLink />
        </a>
      </div>
    </div>
  );

  return (
    <AnimatePresence>
      <div className="farm">
        <FarmHeader />
        <div className="farm_content">
          <FarmStats gcsPlans={gcsPlans} />
          <hr className="mb-32" />
          {!account ? (
            <UnlockWallet />
          ) : isLoading ? (
            <div className="loader">
              <Loader />
            </div>
          ) : !userData.isAllowanceApproved ? (
            <Button disabled={loading || isLoading} onClick={() => handleApprove()}>
              Approve
            </Button>
          ) : (
            renderMethods
          )}
        </div>
      </div>
      {referralModal && (
        <ReferralsModal
          key={"referral"}
          modal={referralModal}
          handleClose={() => setReferralModal(false)}
        />
      )}
      {WithdrawModal !== null && (
        <WithdrawModal
          key={"withdraw"}
          modal={withdrawModal ? true : false}
          handleClose={() => setWithdrawModal(null)}
          stakedDetails={withdrawModal}
          handleGetContractDetails={handleGetContractDetails}
        />
      )}
      {depositModal && (
        <DepositModal
          key={"deposit"}
          modal={depositModal ? true : false}
          handleClose={() => setDepositModal(null)}
          data={depositModal}
          handleGetContractDetails={handleGetContractDetails}
        />
      )}
    </AnimatePresence>
  );
};

export default Farm;
