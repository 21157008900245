import React, { useContext } from 'react';
import token from '../../assets/logo/token.png';
import { UserContext } from '../../store/context/UserContext';

const FarmHeader = () => {
  const {
    userData: { tokenBalance }
  } = useContext(UserContext);

  return (
    <div className="farm_header">
      <div data-position="flex-between">
        <div className="flex">
          <img src={token} alt="token" width={48} />
          <h3>
            Stake <b style={{ fontSize: 'inherit' }}>USDT</b>
          </h3>
        </div>
        <div>
          <h3>
            {new Intl.NumberFormat('en-US', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 4
            }).format(tokenBalance)}{' '}
            USDT
          </h3>
        </div>
      </div>
    </div>
  );
};

export default FarmHeader;
