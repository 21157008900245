import { ethers, utils } from 'ethers';
import {
  BonusType,
  IUserReferrals,
  IUserRewardDetails,
  IUserStakedDetails
} from '../store/types';
import { STAKING_ADDRESS } from './address';
import { sleep } from './contractMethods';
import { loadStakingContract, loadTokenContract } from './contracts';

const AMOUNT = '1000000000000000000000000';

export const formatEther = (value: string) => utils.formatEther(value);

const getUserStatus = (id) => {
  if (id === '0') return 'STARTED';
  return 'FINISHED';
};

export const getUserTokenBalance = async (
  address: string,
  provider: any,
  chainId: number
) => {
  const tokenContract = loadTokenContract(address, provider, chainId);

  const tokenBalance = await tokenContract.balanceOf(address);
  const formattedTokenBalance = utils.formatEther(tokenBalance.toString());

  return Number(formattedTokenBalance);
};

export const getUserAllowance = async (
  address: string,
  provider: any,
  chainId: number
) => {
  const tokenContract = loadTokenContract(address, provider, chainId);

  const userAllowanceInHex = await tokenContract.allowance(
    address,
    STAKING_ADDRESS[chainId]
  );
  const userAllowance = userAllowanceInHex.toString();
  const formatEther = utils.formatEther(userAllowance);

  return Number(formatEther);
};

export const setApprove = async (
  address: string,
  provider: any,
  chainId: number
) => {
  const tokenContract = loadTokenContract(address, provider, chainId);

  const tx = await tokenContract.increaseAllowance(
    STAKING_ADDRESS[chainId],
    AMOUNT
  );
  await tx.wait();
  await sleep();
};

export const getRewards = async (
  address: string,
  provider: any,
  chainId: number,
  id: number
) => {
  const stakingContract = loadStakingContract(address, provider, chainId);
  const rewards = await stakingContract.calculateReward(id, address);

  return {
    totalRewardsWithInitialAmount: Number(formatEther(rewards[0].toString())),
    totalRewards: Number(formatEther(rewards[1].toString())),
    dailyReward: Number(formatEther(rewards[2].toString()))
  };
};

export const getUserStakingDetails = async (
  address: string,
  provider: any,
  chainId: number
) => {
  const stakingContract = loadStakingContract(address, provider, chainId);

  const details = await stakingContract.getUserDetails(address);
  const referrer = await stakingContract.referrer(address);
  const referrerDetails = await stakingContract.getReferrerDetail(address);
  const mylevel = await stakingContract.getLevel(address);
  const level = await stakingContract.getLevel(referrer.referrer.toString());
  const filteredUsers = referrerDetails.users.filter(
    (user) => user.toLowerCase() !== address.toLocaleLowerCase()
  );
  const userRewardDetails = await getUserRewardDetails(
    stakingContract,
    address
  );

  const userStakedDetails: IUserStakedDetails[] = details.map((d, index) => {
    const staked_amount = formatEther(d.amount.toString());

    return {
      staked_amount: Number(staked_amount),
      apr: Number(d.apr.toString()),
      start_time: Number(d.starttime.toString()) * 1000,
      end_time: Number(d.endtime.toString()) * 1000,
      days: Number(d.day.toString()),
      plan: Number(d.plan.toString()),
      old_user: d.stake === true ? true : false,
      status: getUserStatus(d.status.toString()),
      index
    };
  });

  const ongoingStakingDetails = userStakedDetails.filter(
    (f) => f.status === 'STARTED'
  );
  const finishedStakingDetails = userStakedDetails.filter(
    (f) => f.status === 'FINISHED'
  );

  return {
    mylevel: Number(mylevel.toString()),
    level: Number(level.toString()),
    referral_id: Number(referrerDetails.id.toString()),
    referred_users: filteredUsers,
    ongoingStakingDetails,
    finishedStakingDetails,
    referrer: referrer.referrer.toString(),
    referrer_endtime: referrer.referrerendtime.toString(),
    userRewardDetails
  };
};

export const getUserReferrals = async (
  address: string,
  provider: any,
  chainId: number
) => {
  const stakingContract = loadStakingContract(address, provider, chainId);
  const referrerAddresses = await stakingContract.getstakeReferrers(address);

  const referralsList: IUserReferrals[] = await Promise.all(
    referrerAddresses.map(async (ref) => {
      const data = await stakingContract.getStakeAmount(address, ref);
      const formattedBalance = ethers.utils.formatEther(data.toString());
      return {
        referee_address: ref,
        earned: Number(formattedBalance)
      };
    })
  );

  return referralsList;
};

export const getReferreeAddress = async (
  address: string,
  provider: any,
  chainId: number,
  id: string
) => {
  const stakingContract = loadStakingContract(address, provider, chainId);
  const referrerAddresses = await stakingContract.getAddress(id);

  console.log(referrerAddresses);
  return referrerAddresses.toString();
};

export const getUserRewardDetails = async (
  stakingContract: ethers.Contract,
  address: string
) => {
  const data = await stakingContract.getRewardAmount(address);

  const res: IUserRewardDetails[] = data.map((d) => {
    return {
      address: d._address.toLowerCase(),
      earnedBonus: formatEther(d.amount.toString()),
      type: d[0].toString() === '0' ? BonusType.DIRECT : BonusType.REFERRAL_APR
    };
  });

  return res;
};
