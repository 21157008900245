export const modalVaraints = {
  initial: {
    opacity: 0,
    scale: 0.5,
    x: '-50%',
    y: '-50%'
  },
  animate: {
    opacity: 1,
    transition: { duration: 0.3 },
    scale: 1,
    x: '-50%',
    y: '-50%'
  },
  exit: {
    opacity: 0,
    scale: 0,
    x: '-50%',
    y: '-50%'
  }
};
