import React, { useContext, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useWeb3React } from '@web3-react/core';

import Backdrop from './Backdrop';
import './Modal.scss';
import Button from '../Button';
import { modalVaraints } from '../../helpers/animation';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import withdrawIcon from '../../assets/images/withdraw.png';
import autostakeIcon from '../../assets/images/autostake.png';
import { TransactionContext } from '../../store/context/TransactionContext';
import { claimAndRestake, claimAndWithdraw } from '../../utils/contractMethods';
import { IUserStakedDetails } from '../../store/types';
import { useUserStakingData } from '../../hooks';

interface IWithdrawModal {
  modal: boolean;
  handleClose?: () => void;
  stakedDetails: IUserStakedDetails | null;
  handleGetContractDetails: () => Promise<void>;
}

const WithdrawModal: React.FC<IWithdrawModal> = ({
  modal,
  handleClose,
  stakedDetails,
  handleGetContractDetails
}) => {
  const { account, library, chainId } = useWeb3React();
  const { refetch } = useUserStakingData();
  const { setTransaction, loading } = useContext(TransactionContext);
  const [planId, setPlanId] = useState(stakedDetails?.plan);

  useEffect(() => {
    if (stakedDetails) setPlanId(stakedDetails.plan);
  }, [stakedDetails]);

  const handleClaimAndWithdraw = async () => {
    if (!account || !chainId)
      return setTransaction({
        loading: true,
        status: 'error',
        message: 'No web3 wallet is connected'
      });
    try {
      if (!stakedDetails) return;
      setTransaction({ loading: true, status: 'pending' });
      await claimAndWithdraw(
        account,
        library?.provider,
        chainId,
        stakedDetails?.index
      );
      await refetch();
      handleGetContractDetails();
      if (handleClose) handleClose();
      setTransaction({ loading: true, status: 'success' });
    } catch (error: any) {
      console.log(error);
      if (error?.code === 4001) {
        return setTransaction({
          loading: true,
          status: 'error',
          message: error.message
        });
      }
      setTransaction({ loading: true, status: 'error' });
    }
  };

  const handleClaimAndRestake = async () => {
    if (!account || !chainId)
      return setTransaction({
        loading: true,
        status: 'error',
        message: 'No web3 wallet is connected'
      });
    try {
      if (planId === undefined) return;
      if (!stakedDetails) return;
      setTransaction({ loading: true, status: 'pending' });
      await claimAndRestake(
        account,
        library?.provider,
        chainId,
        stakedDetails?.index,
        planId
      );
      await refetch();
      handleGetContractDetails();
      if (handleClose) handleClose();
      setTransaction({ loading: true, status: 'success' });
    } catch (error: any) {
      console.log(error);
      if (error?.code === 4001) {
        return setTransaction({
          loading: true,
          status: 'error',
          message: error.message
        });
      }
      setTransaction({ loading: true, status: 'error' });
    }
  };

  return (
    <Backdrop handleClose={handleClose} isOpen={modal}>
      <AnimatePresence exitBeforeEnter>
        {modal && (
          <motion.div
            className={'withdraw_modal'}
            onClick={(e) => e.stopPropagation()}
            variants={modalVaraints}
            animate="animate"
            initial="initial"
            exit="exit"
          >
            <div className="withdraw_modal-content">
              <div className="modal-header">
                <h3>Withdraw</h3>
                <div
                  className="close"
                  onClick={() => handleClose && handleClose()}
                >
                  <CloseIcon />
                </div>
              </div>
              <div className="content">
                <div>
                  <p>Claim and Withdraw</p>
                  <img src={withdrawIcon} alt="withdraw" />
                  {/* <div className="withdraw-controls">
                    <p>withdraw</p>
                    <b>180 GCS</b>
                  </div> */}
                  <Button
                    disabled={loading}
                    onClick={() => handleClaimAndWithdraw()}
                  >
                    Confirm
                  </Button>
                </div>
                <div>
                  <p>Claim and Restake</p>
                  <img src={autostakeIcon} alt="restake" />
                  {/* <div className="restake-controls">
                    {gcsPlans.map((m) => (
                      <button
                        key={m.plan_id}
                        onClick={() => setPlanId(m.plan_id)}
                      >
                        {m.day}d
                      </button>
                    ))}
                  </div> */}
                  <Button
                    disabled={loading}
                    onClick={() => handleClaimAndRestake()}
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </Backdrop>
  );
};

export default WithdrawModal;
