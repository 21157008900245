import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import CopyToClipboard from 'react-copy-to-clipboard';

import { ReactComponent as Loader } from '../../assets/icons/transaction_processing.svg';
import { ReactComponent as Check } from '../../assets/icons/lock.svg';
import { ReactComponent as Copy } from '../../assets/icons/copy.svg';
import { Button } from '../../components';
import { getSlicedValue } from '../../helpers/methods';
import { TransactionContext } from '../../store/context/TransactionContext';
import { useUpdateEffect } from '../../hooks';
import { sleep, zeroAddress } from '../../utils/contractMethods';

interface IReferralPage {
  referral_id: string;
}

const ReferralPage: React.FC<IReferralPage> = ({ referral_id }) => {
  const { account, chainId, library } = useWeb3React();
  const [loading, setLoading] = useState(true);
  const [refAddress, setRefAddress] = useState<string | undefined>();
  const { setTransaction } = useContext(TransactionContext);
  const [copied, setCopied] = useState(false);

  useUpdateEffect(() => {
    if (!copied) return;

    const clear = setTimeout(() => setCopied(false), 3000);

    return () => {
      clearTimeout(clear);
    };
  }, [copied]);

  const handleGetReferralDetails = useCallback(async () => {
    if (account && chainId) {
      setLoading(true);
      try {
        const { getReferreeAddress } = await import('../../utils/userMethods');
        setRefAddress(
          await getReferreeAddress(
            account,
            library?.provider,
            chainId,
            referral_id
          )
        );
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  }, [referral_id, account, chainId, library]);

  useEffect(() => {
    handleGetReferralDetails();
  }, [handleGetReferralDetails]);

  const handleAddReferrer = async () => {
    if (!account || !chainId) return;
    try {
      setTransaction({ loading: true, status: 'pending' });
      const { addReferrer } = await import('../../utils/contractMethods');
      await addReferrer(account, library?.provider, chainId, refAddress);
      setTransaction({ loading: true, status: 'success' });
      await sleep();
      window.location.href = '/';
    } catch (error: any) {
      if (error?.code === 4001) {
        return setTransaction({
          loading: true,
          status: 'error',
          message: error.message
        });
      }
      if (error?.code === -32603 && error?.data?.message) {
        return setTransaction({
          loading: true,
          status: 'error',
          message: error.data.message.split(':')[1]
        });
      }
      setTransaction({ loading: true, status: 'error' });
    }
  };

  if (!account) {
    return (
      <div>
        <p>Connect wallet to add referrer</p>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="loader">
        <Loader />
      </div>
    );
  }

  if (refAddress === zeroAddress) {
    return <div className="loader">Invalid Referral Id</div>;
  }

  if (refAddress?.toLocaleLowerCase() === account.toLocaleLowerCase()) {
    return <div className="loader">you can't referrer yourself</div>;
  }

  return (
    <div className="farm">
      <div className="farm_header">
        <div data-position="flex-between">
          <div className="flex">
            <h3>Add Referrer</h3>
          </div>
        </div>
      </div>
      <div className="farm_content">
        <div data-position="flex-between" className="mb-20">
          <p>Referral Id</p>
          <h4>{referral_id}</h4>
        </div>
        {refAddress && (
          <>
            <div data-position="flex-between" className="mb-20">
              <p>Referral Address</p>
              <div data-position="flex">
                <h4>{getSlicedValue(refAddress)}</h4>
                {copied ? (
                  <Check />
                ) : (
                  <CopyToClipboard
                    text={refAddress}
                    onCopy={() => setCopied(true)}
                  >
                    <div className="pointer">
                      <Copy />
                    </div>
                  </CopyToClipboard>
                )}
              </div>
            </div>
            <Button onClick={() => handleAddReferrer()}>Add Referrer</Button>
          </>
        )}
      </div>
    </div>
  );
};

export default ReferralPage;
