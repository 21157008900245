import { ethers } from "ethers";
import { STAKING_ADDRESS, TOKEN_ADDRESS } from "./address";
import tokenAbi from "./abi/gcstoken.json";
import stakingAbi from "./abi/gcsstaking.json";

export const loadTokenContract = (
  address: string,
  provider: any,
  chainId: number
) => {
  const etherProvider = new ethers.providers.Web3Provider(provider);
  const signer = etherProvider.getSigner(address);
  const tokenContract = new ethers.Contract(
    TOKEN_ADDRESS[chainId],
    tokenAbi,
    signer
  );

  return tokenContract;
};

export const loadStakingContract = (
  address: string,
  provider: any,
  chainId: number
) => {
  const etherProvider = new ethers.providers.Web3Provider(provider);
  const signer = etherProvider.getSigner(address);
  const stakingContract = new ethers.Contract(
    STAKING_ADDRESS[chainId],
    stakingAbi,
    signer
  );

  return stakingContract;
};
