import React from 'react';
import Countdown, { CountdownRenderProps } from 'react-countdown';

// import { ReactComponent as Lock } from '../../assets/icons/lock.svg';
// import { ReactComponent as Unlock } from '../../assets/icons/unlock.svg';
import { Button } from '../../components';
import { IUserStakedDetails } from '../../store/types';

interface IWithdraw {
  setWithdrawModal: React.Dispatch<
    React.SetStateAction<IUserStakedDetails | null>
  >;
  stakedDetails: IUserStakedDetails;
}

const Withdraw: React.FC<IWithdraw> = ({ setWithdrawModal, stakedDetails }) => {
  const renderer = ({
    completed,
    days,
    hours,
    minutes,
    seconds
  }: CountdownRenderProps) => {
    if (completed) {
      return (
        <>
          {/* <label data-position="flex" htmlFor="deposit">
            <p className="mr-5">Withdraw</p>
            <Unlock />
          </label> */}
          <Button onClick={() => setWithdrawModal(stakedDetails)}>
            Withdraw
          </Button>
        </>
      );
    } else {
      return (
        <>
          {/* <label data-position="flex" htmlFor="deposit">
            <p className="mr-5">Withdraw</p>
            <Lock />
          </label> */}
          <Button disabled>
            <span>
              {days}d : {hours}h : {minutes}m : {seconds}s
            </span>
          </Button>
        </>
      );
    }
  };
  return (
    <div className="form_input">
      <Countdown date={stakedDetails.end_time} renderer={renderer} />
    </div>
  );
};

export default Withdraw;
