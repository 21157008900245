import React, { useCallback, useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { getRewards } from '../../utils/userMethods';

const Rewards: React.FC<{ id: number }> = ({ id }) => {
  const { account, library, chainId } = useWeb3React();
  const [rewards, setRewards] = useState({
    totalRewardsWithInitialAmount: 0,
    totalRewards: 0,
    dailyReward: 0
  });

  const handleGetReward = useCallback(async () => {
    if (account && chainId) {
      setRewards(await getRewards(account, library?.provider, chainId, id));
    }
  }, [account, chainId, library, id]);

  useEffect(() => {
    handleGetReward();
  }, [handleGetReward]);

  return (
    <div>
      <p>Earned rewards</p>
      <b>
        {new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 4
        }).format(rewards.totalRewards)}{' '}
        USDT
      </b>
    </div>
  );
};

export default React.memo(Rewards);
