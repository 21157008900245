import { ethers } from "ethers";
import { loadStakingContract } from "./contracts";

export const zeroAddress = "0x0000000000000000000000000000000000000000";

export const sleep = (ms = 2000) => new Promise((resolve) => setTimeout(resolve, ms));

export const getGcsPlanData = async (address: string, provider: any, chainId: number) => {
  const stakingContract = loadStakingContract(address, provider, chainId);
  const details = await stakingContract.getPlanDetails();

  const gcsPlansData = details.map((d) => {
    const parseStaked = ethers.utils.formatEther(d.staked.toString()).toString();

    return {
      name: d.name.toString(),
      apr: Number(d.apr.toString()),
      plan_id: Number(d.planid.toString()),
      stakeaddress: d.stakeaddress,
      day: Number(d.day.toString()),
      directbonus: Number(d.directbonus.toString()),
      gcexaddress: d.gcexaddress.toString(),
      total_staked: Number(parseStaked),
      withdrawfee: Number(d.withdrawfee.toString()),
      rewardaprbonus: Number(d.rewardaprbonus.toString()),
      level1DirectBonus: Number(d.level1DirectBonus.toString()),
      level2DirectBonus: Number(d.level2DirectBonus.toString()),
      validcount: Number(d.validcount.toString()),
      xamount: ethers.utils.formatEther(d.amount[0].toString()),
      yamount: ethers.utils.formatEther(d.amount[1].toString()),
      zamount: ethers.utils.formatEther(d.amount[2].toString()),
    };
  });

  const data = ["Bronze", "Silver", "Gold"];

  const updatdPlan = gcsPlansData.concat(gcsPlansData[0]).map((f, i) => ({ ...f, name: data[i] }));
  return updatdPlan;
};

export const setStake = async (
  address: string,
  provider: any,
  chainId: number,
  amount: string,
  plan: number,
  level: number,
  referrer
) => {
  const stakingContract = loadStakingContract(address, provider, chainId);
  const parseAmount = ethers.utils.parseEther(amount).toString();

  if (referrer === zeroAddress) {
    const tx = await stakingContract.level1stake(parseAmount, plan);
    await tx.wait();
    await sleep(1000);
    return;
  }

  if (level === 1) {
    const tx = await stakingContract.level2stake(parseAmount, plan, referrer);
    await tx.wait();
    await sleep(1000);
    return;
  }

  if (level === 2) {
    console.log("3 is called");
    console.log(referrer);

    const tx = await stakingContract.level3stake(parseAmount, plan, referrer);
    await tx.wait();
    await sleep(1000);
    return;
  }
};

export const claimAndWithdraw = async (
  address: string,
  provider: any,
  chainId: number,
  id: number
) => {
  const stakingContract = loadStakingContract(address, provider, chainId);

  const tx = await stakingContract.withdraw(id);
  await tx.wait();
};

export const claimAndRestake = async (
  address: string,
  provider: any,
  chainId: number,
  id: number,
  planId: number
) => {
  const stakingContract = loadStakingContract(address, provider, chainId);

  const tx = await stakingContract.claimrewardandAutoStake(id, planId);
  await tx.wait();
};

export const addReferrer = async (address: string, provider: any, chainId: number, referrer) => {
  const stakingContract = loadStakingContract(address, provider, chainId);

  const isValid = ethers.utils.isAddress(referrer);

  if (!isValid) throw new Error("Invalid ethereum address");

  const tx = await stakingContract.addReferrer(referrer);
  await tx.wait();
};
