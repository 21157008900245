import { AnimatePresence, motion } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";

import { ReactComponent as UpArrow } from "../../assets/icons/up_arrow.svg";

import { Button } from "../../components";
import { useUpdateEffect } from "../../hooks";
import { TransactionContext } from "../../store/context/TransactionContext";
import { IDepositData, IGcsPlan } from "../../store/types";

interface IDeposit {
  setDepositModal: React.Dispatch<React.SetStateAction<IDepositData | null>>;
  gcsPlans: IGcsPlan[];
}

const Deposit: React.FC<IDeposit> = ({ setDepositModal, gcsPlans }) => {
  const { loading } = useContext(TransactionContext);
  const [deposit, setDeposit] = useState("");
  const [days, setDays] = useState(0);
  const [planName, setPlanName] = useState("Bronze");
  const [apr, setApr] = useState(0);
  const [planId, setPlanId] = useState(0);
  const [dropdown, setDropdown] = useState(false);

  useEffect(() => {
    if (gcsPlans.length) {
      setApr(gcsPlans[0].apr);
      setDays(gcsPlans[0].day);
    }
  }, [gcsPlans]);

  useUpdateEffect(() => {
    if (days) {
      setApr(gcsPlans.find((f) => f.day === days)?.apr ?? 0);
      setPlanId(gcsPlans.find((f) => f.day === days)?.plan_id ?? 0);
    }
  }, [days]);

  const disableBtn = loading || !deposit || Number(deposit) <= 0;

  return (
    <>
      <div className="form_input">
        <div className="deposit_container">
          <label htmlFor="deposit">
            <p>Deposit</p>
          </label>
          <div
            className={
              dropdown ? "deposit_container-dropdown active" : "deposit_container-dropdown"
            }
            onClick={() => setDropdown((d) => !d)}
          >
            <p className={dropdown ? "dropdown-header active" : "dropdown-header"}>
              <span>{planName}</span>
              <UpArrow />
            </p>
            <AnimatePresence>
              {dropdown && (
                <motion.div
                  className="dropdown-list"
                  animate={{ opacity: 1 }}
                  initial={{ opacity: 0 }}
                  exit={{ opacity: 0 }}
                >
                  {gcsPlans.map((plan, index) => (
                    <p
                      key={index.toString()}
                      onClick={() => {
                        setDays(plan.day);
                        setPlanName(plan.name);
                      }}
                    >
                      {plan.name}
                    </p>
                  ))}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
        <div>
          <input
            type="number"
            id="deposit"
            placeholder="0.0"
            min="0"
            value={deposit}
            onChange={({ target }) => setDeposit(target.value)}
          />
        </div>
        <Button
          disabled={disableBtn}
          onClick={() =>
            setDepositModal({
              modal: true,
              days,
              depositAmount: deposit,
              apr,
              planId,
            })
          }
        >
          Deposit
        </Button>
      </div>
    </>
  );
};

export default Deposit;
