import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Backdrop from './Backdrop';
import './Modal.scss';
import { ReactComponent as FailedIcon } from '../../assets/icons/transaction_failed.svg';
import { ReactComponent as SuccessIcon } from '../../assets/icons/transaction_success.svg';
import { ReactComponent as ProcessIcon } from '../../assets/icons/transaction_processing.svg';
import { modalVaraints } from '../../helpers/animation';

interface ITransactionModal {
  modal: boolean;
  handleClose?: () => void;
  message?: string;
  status: 'pending' | 'success' | 'error';
}

const TransactionModal: React.FC<ITransactionModal> = ({
  modal,
  handleClose,
  message,
  status
}) => {
  const getTitle = () => {
    switch (status) {
      case 'error':
        return 'Transaction Failed';
      case 'success':
        return 'Transaction Confirmed';
      default:
        return 'Transaction Processing!';
    }
  };

  const getContent = () => {
    switch (status) {
      case 'error':
        return 'Sorry! Your transaction did not complete. Please try again later.';
      case 'success':
        return 'Congratulations! Your transaction is complete. It will reflect your wallet soon.';
      default:
        return 'Good news your transaction is currently processing, please sit back and relax while blocks are validating';
    }
  };

  const getIcon = () => {
    switch (status) {
      case 'error':
        return <FailedIcon />;
      case 'success':
        return <SuccessIcon />;
      default:
        return (
          <div className="loader size-100">
            <ProcessIcon />
          </div>
        );
    }
  };
  return (
    <Backdrop handleClose={handleClose} isOpen={modal}>
      <AnimatePresence exitBeforeEnter>
        {modal && (
          <motion.div
            className={'transaction_modal'}
            onClick={(e) => e.stopPropagation()}
            variants={modalVaraints}
            animate="animate"
            initial="initial"
            exit="exit"
          >
            <div className="transaction_modal-content">
              <div className="icon">{getIcon()}</div>
              <h3>{getTitle()}</h3>
              <p>{message ?? getContent()}</p>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </Backdrop>
  );
};

export default TransactionModal;
