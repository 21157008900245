import React, { useState } from 'react';
import { motion } from 'framer-motion';
import CopyToClipboard from 'react-copy-to-clipboard';

import './Modal.scss';
import Backdrop from './Backdrop';
import { modalVaraints } from '../../helpers/animation';
import { ReactComponent as Share } from '../../assets/icons/share.svg';
import { ReactComponent as Copy } from '../../assets/icons/copy.svg';
import { ReactComponent as NoReferrals } from '../../assets/icons/no_referrals.svg';
import { ReactComponent as ProcessIcon } from '../../assets/icons/transaction_processing.svg';
import Button from '../Button';
import { useUpdateEffect, useUserStakingData } from '../../hooks';
import { getSlicedValue, getTotalValue } from '../../helpers/methods';

const getBaseUrl = () => {
  const splitedurl = window.location.href.split('://');
  const domain = splitedurl[1].split('/')[0];
  return `${splitedurl[0]}://${domain}`;
};

interface IReferralsModal {
  modal: boolean;
  handleClose?: () => void;
}

const CopyText = ({ address }) => {
  const [copied, setCopied] = useState(false);

  useUpdateEffect(() => {
    if (!copied) return;

    const clear = setTimeout(() => setCopied(false), 3000);

    return () => {
      clearTimeout(clear);
    };
  }, [copied]);

  if (copied) {
    return <p style={{ lineHeight: '24px' }}>Copied</p>;
  }

  return (
    <CopyToClipboard text={address} onCopy={() => setCopied(true)}>
      <div className="pointer">
        <Copy />
      </div>
    </CopyToClipboard>
  );
};

const ReferralsModal: React.FC<IReferralsModal> = ({ modal, handleClose }) => {
  const [copied, setCopied] = useState(false);
  const {
    userData: { referral_id, referred_users, mylevel, userRewardDetails }
  } = useUserStakingData();

  useUpdateEffect(() => {
    if (!copied) return;

    const clear = setTimeout(() => setCopied(false), 3000);

    return () => {
      clearTimeout(clear);
    };
  }, [copied]);

  return (
    <Backdrop handleClose={handleClose} isOpen={modal}>
      <motion.div
        className={'referrals_modal'}
        onClick={(e) => e.stopPropagation()}
        variants={modalVaraints}
        animate="animate"
        initial="initial"
        exit="exit"
      >
        <div className="referrals_modal-content">
          <div className="modal-header">
            <h3>My Referrals</h3>
            {copied ? (
              <Button variant="primary_gradient">Link Copied</Button>
            ) : (
              <CopyToClipboard
                text={`${getBaseUrl()}?referral_id=${referral_id}`}
                onCopy={() => setCopied(true)}
              >
                <Button variant="primary_gradient">
                  <Share />
                  <span>Get Invite Link</span>
                </Button>
              </CopyToClipboard>
            )}
          </div>
          <div className="content">
            {!referred_users ? (
              <div className="loader">
                <ProcessIcon />
              </div>
            ) : !referred_users.length ? (
              <div className="no_referrals">
                <NoReferrals />
                <p>No referrals on your list.</p>
              </div>
            ) : (
              <div className="referrals_list">
                {referred_users.map((ref, index) => {
                  return (
                    <div key={index.toString()}>
                      <div>
                        <p>{getSlicedValue(ref)}</p>
                        <b>{getTotalValue(userRewardDetails, ref)} USDT</b>
                      </div>
                      <div>
                        <CopyText address={ref} />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div
            className="pt-20"
            data-position="flex"
            style={{ justifyContent: 'flex-end' }}
          >
            <strong> MY LEVEL - {mylevel}</strong>
          </div>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default ReferralsModal;
