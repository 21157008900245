const TOKEN_ADDRESS = {
  56: '',
  // 97: '0xF8ceC2Aa2124bd520a945BAc7BA4FEE2b0180303'
  97: '0x5222c040001eEA5247729f9Cce6bdB54055B1Ad6'
};

const STAKING_ADDRESS = {
  56: '',
  97: '0x0473554daE10686235AB8043F7Af603C94e8e4B9'
};

export { TOKEN_ADDRESS, STAKING_ADDRESS };
